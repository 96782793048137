import React from 'react';

export const useResizeableContainer = (minWidth) => {
  const [isResizing, setIsResizing] = React.useState(false);
  const [width, setWidth] = React.useState(minWidth);

  const enableResize = React.useCallback(() => {
    setIsResizing(true);
  }, [setIsResizing]);

  const disableResize = React.useCallback(() => {
    setIsResizing(false);
  }, [setIsResizing]);

  const resize = React.useCallback(
    (e) => {
      if (isResizing) {
        const newWidth = e.clientX; // You may want to add some offset here from props
        if (newWidth >= minWidth) {
          setWidth(newWidth);
        }
      }
    },
    [minWidth, isResizing, setWidth],
  );

  React.useEffect(() => {
    document.addEventListener('mousemove', resize);
    document.addEventListener('mouseup', disableResize);

    return () => {
      document.removeEventListener('mousemove', resize);
      document.removeEventListener('mouseup', disableResize);
    };
  }, [disableResize, resize]);

  return { width, enableResize };
};
