import React from 'react';
import { IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import MinimizeIcon from '@mui/icons-material/FullscreenExit';
import MaximizeIcon from '@mui/icons-material/Fullscreen';

export const ModalWrapper = ({
  isOpen,
  handleClose,
  children,
  title,
  boxContainerProps,
  hideBackdrop = false,
  boxContentProps,
  modalOptions,
}) => {
  const [isMaximized, setIsMaximized] = React.useState(0);
  const defaultStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '500px',
    height: '500px',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    borderRadius: '0.1rem',
  };
  const containerStyles = isMaximized
    ? { ...boxContainerProps, width: '100% !important', height: '100vh' }
    : boxContainerProps;

  const handleToggleMaximize = () => setIsMaximized((prev) => !prev);

  return (
    <Modal open={isOpen} onClose={handleClose} hideBackdrop={hideBackdrop}>
      <Box
        sx={{
          ...defaultStyles,
          ...containerStyles,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            bgcolor: '#233565',
            p: 0,
            borderTopLeftRadius: '0.1rem',
            borderTopRightRadius: '0.1rem',
          }}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', paddingLeft: '12px', fontSize: '0.8rem', color: 'white' }}>
            {title}
          </Typography>
          <div>
            {modalOptions || null}

            <IconButton key="minimize" onClick={handleToggleMaximize}>
              {isMaximized ? <MinimizeIcon /> : <MaximizeIcon />}
            </IconButton>

            <IconButton size="small" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </Box>
        <Box
          sx={{
            display: 'flex',
            overflow: 'auto',
            height: '100%',
            maxHeight: 'calc(100vh - 80px)',
            ...boxContentProps,
          }}
        >
          {children}
        </Box>
      </Box>
    </Modal>
  );
};
