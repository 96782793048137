import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { ModalWrapper } from './ui';
import {Api} from '../lib/api';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-monokai';

const ModelDataPanel = ({ modelName, parentPath, fullPath, modelType, modelIcon, updateName, handleClose }) => {
  
  const [jsonText, setJsonText] = useState(JSON.stringify({}, null, 2));
  const [changed, setChanged] = useState(false);
  let model = {};

  const { data: modelData, isLoading } = useQuery({
    queryKey: ['modelData', model],
    queryFn: () =>
    Api.adminCall({
        Method: 'Read',
        ModelType: modelType,
        Payload: fullPath ?? `${parentPath}/${modelName}`,
      }),
    retry: 0,
  });

  const saveDataBeforeClose = async () => {
    
    if(!changed) {
      handleClose();
      return;
    }

    let savePayload = JSON.parse(jsonText);
    savePayload.ParentPath = parentPath;

    const request = {
      Method: 'Create',
      ModelType: modelType,
      Payload: savePayload,
      ParentPath: parentPath,
      FullPath: fullPath
    };

    try {
      await Api.adminCall(request);
      if(updateName) updateName(request.Payload.Name);
      toast.success('Model saved successfully');
    } 
    catch (error) {

      if (error instanceof Error) {
        toast.error(error.message);
        return;
      }

      toast.error('Unable to save Model');
    } 
    finally {
      handleClose();
    }
  };

  useEffect(() => {
    setJsonText(JSON.stringify(modelData, null, 2));
  }, [modelData]);

  return (
    <ModalWrapper isOpen={true} handleClose={saveDataBeforeClose} boxContainerProps={{ minWidth: 550 }} title={
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box component={modelIcon} sx={{ mr: 0.5, fontSize: '1.3rem' }} />
          {modelName}
        </Box>
      }
    >
      <Stack gap={2} sx={{ p: 2, width: '100%', height: '100%' }}>
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <AceEditor mode="json" height="Calc(100% - 40px)" width="100%" value={jsonText} onChange={(text) => {setJsonText(text); setChanged(true);}} 
            name="UNIQUE_ID_OF_DIV" editorProps={{ $blockScrolling: true }} setOptions={{ useWorker: false }} />
        )}
      </Stack>
    </ModalWrapper>
  );
};

export default ModelDataPanel;