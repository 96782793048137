import { createTheme } from '@mui/material';

export const theme = createTheme({
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: '#233565',
    },
  },
});
