import Auth from './lib/api';

export const appConfig = {
  appName: "{{ENV_APP_NAME}}",
  favIcon: '{{ENV_DOCUMENT_ICON}}',
};

export const cognitoUserPoolConfig = () => {
  const pathname = window.location.origin;

  if (pathname === 'http://localhost:3000') {
    return {
      REGION: 'ap-southeast-2',
      USER_POOL_ID: 'ap-southeast-2_eneiuQNoJ',
      USER_POOL_APP_CLIENT_ID: '66r52lbp0cpm4ntiaj7m56fssg',
    };
  } else {
    // return {
    //   REGION: '{{AWS_REGION}}',
    //   USER_POOL_ID: '{{ENV_AWS_USER_POOL_ID}}',
    //   USER_POOL_APP_CLIENT_ID: '{{ENV_AWS_USER_POOL_APP_CLIENT_ID}}',
    // };
    return {
      REGION: 'ap-southeast-2',
      USER_POOL_ID: 'ap-southeast-2_eneiuQNoJ',
      USER_POOL_APP_CLIENT_ID: '66r52lbp0cpm4ntiaj7m56fssg',
    };
  }
};

// const LoadUpNamespaces = async () => {
//   for(var i = 0; i < namespaces.length; i++) if(!namespaces[i].Microservice.Url) await loadMicroservice(namespaces[i].Microservice);
// }

// const loadMicroservice = async (microserviceName) => {
//   var fullService = await Auth.readCall('EAI.Domain.Microservice', microserviceName);
//   namespaces.filter(x => x.Microservice == microserviceName).forEach(x => x.Microservice = fullService);
// }

export const adminUrl = (namespace) => {
  
  if(namespace == null){
    const pathname = window.location.origin;
    /*if (pathname === 'http://localhost:3000')*/ return 'https://3f2xz7e4snukkpxz6q46e325i40vqape.lambda-url.us-east-1.on.aws/';
    //else return 'https://{{ENV_AWS_ADMIN_LAMBDA_URL}}.lambda-url.{{AWS_REGION}}.on.aws/';
  }
  //else{
    //var loadedNamespace = namespaces.filter(x => x.Name == namespace);
    //if(!loadedNamespace) throw new Error(namespace + ' is not a registered namespace');
    //return loadedNamespace.Microservice.Url;
  //}
};

export const marketUrl = (namespace) => {
  
  if(namespace == null){
    const pathname = window.location.origin;
    /*if (pathname === 'http://localhost:3000')*/ return 'https://g5ej5embbbysrulbf4umxuwzcu0yvyjt.lambda-url.us-east-1.on.aws/';
    //else return 'https://{{ENV_AWS_MARKET_LAMBDA_URL}}.lambda-url.{{AWS_REGION}}.on.aws/';
  }
  //else{
    //var loadedNamespace = namespaces.filter(x => x.Name == namespace);
    //if(!loadedNamespace) throw new Error(namespace + ' is not a registered namespace');
    //return loadedNamespace.Microservice.Url;
  //}
};
// const namespaces = await Auth.listAllCall('EAI.Domain.Namespace');
// LoadUpNamespaces();

