import { useQuery } from '@tanstack/react-query';
import {Api} from '../../../lib/api'

export const useDomains = () => {
  return useQuery({
    queryKey: ['domains'],
    queryFn: () => Api.adminCall({
      Method: 'ReadAll',
      ModelType: 'EAI.Service.Domain'
    }),
    keepPreviousData: true,
  });
};
