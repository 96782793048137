import React, {useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Box, IconButton, Divider, Tooltip } from '@mui/material';
import { useGlobalStore } from '../../store/global.store';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { EnterpriseTreeView } from '../../features/domains';
import { Navbar, DrawerHeader, MainContainer, Sidebar } from '../layout';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import 'react-toastify/dist/ReactToastify.css';
import {Api} from '../../lib/api';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ChatBox, { ChatFrame } from 'react-chat-plugin';

export const RootLayout = ({ children }) => {

  const { drawerWidth, isDrawerOpen, setIsDrawerOpen } = useGlobalStore();
  const [chat, setChat] = useState(false);

  const [messages, setMessages] = useState([{    
    text: 'Teena is online',
    timestamp: 1578366389250,
    type: 'notification',
  }]);
 
  const toggleChat = () => {
    setChat(!chat);
  }

  const addUserMessage = (message) => {
    
    const newMessages = messages.concat({
      author: {
        username: 'Me',
        id: 1,
        avatarUrl: '/User-avatar.svg',
      },
        text: message,
        timestamp: +new Date(),
        type: 'text',
      });

    setMessages(newMessages);
    let method = 'Reply';
    if(message == "hi") method = 'Reset';

    Api.marketTextCall({ModelType: 'EAI.Chain.Convo', Method: method, Payload: message})
    
    .then(result => {
      console.log("chat response: " + result);
      addBotMessage(newMessages, result);
    })
    
    .catch(error => {
      if(!error.message?.startsWith('403')) console.error('Error:', error);
    });
  };

  const addBotMessage = (oldMessages, message) => {
    setMessages(oldMessages.concat({
      author: {
        username: 'Teena',
        id: 2,
        avatarUrl: '/robot.png',
      },
        text: message,
        timestamp: +new Date(),
        type: 'text',
      }));
  };

  return (
    <>
      <Navbar />

      <Box sx={{ display: 'flex', minHeight: 'calc(100vh - 64px)' }}>
        <Sidebar drawerWidth={drawerWidth} open={isDrawerOpen}>
          <DrawerHeader />

          <DrawerHeader>
            <IconButton onClick={() => setIsDrawerOpen(false)}>
              <ChevronLeftIcon />
            </IconButton>
          </DrawerHeader>

          <Divider />
            {!chat && (<EnterpriseTreeView /> )}
            {chat && (<ChatBox messages={messages} userId={1} onSendMessage={addUserMessage} width={'500px'}  />)}
          <Divider />

          <div>
            
            <IconButton onClick={() => toggleChat()}  sx={{width: '50%'}}>
            {chat ? <AccountTreeOutlinedIcon/> : <ChatBubbleOutlineOutlinedIcon/>}
            </IconButton>

          </div>

        </Sidebar>
        <MainContainer open={isDrawerOpen}>
          <DrawerHeader />

          {!isDrawerOpen && (
            <Tooltip title="Open Drawer">
              <IconButton onClick={() => setIsDrawerOpen(true)} sx={{ color: 'gray.500', display: 'flex' }}>
                <AccountTreeOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}

          {children}
        </MainContainer>
      </Box>

      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        theme="colored"
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
    </>
  );
};
