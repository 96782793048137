import { useQuery } from '@tanstack/react-query';
import {Api} from '../../../lib/api';

export const useChildDomains = (ParentId) => {
  return useQuery({
    queryKey: ['childDomains', ParentId],
    queryFn: () => Api.listByParentCall('EAI.Service.Domain', ParentId),
    enabled: !!ParentId,
  });
};
