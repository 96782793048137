import { Auth } from 'aws-amplify';
import { adminUrl, marketUrl } from '../env';

const textCall = async (url, request) => {

    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();

    const response = await fetch(url, {
        
        method: 'POST',
        body: JSON.stringify(request),

        headers:new Headers({
            'Authorization': token
        })

      });
    
    if (!response.ok) throw new Error(response.Message);
    return response;
};

const apiCall = async (url, request) => (await textCall(url, request)).json();

const marketTextCall = async(request) => (await textCall(marketUrl(), request)).text();
const marketCall = async(request) => await apiCall(marketUrl(), request);
const adminCall = async(request) => await apiCall(adminUrl(), request);
const modelCall = async(modelType, request) => await apiCall(adminUrl(extractNamespace(modelType)), request);
const createCall = async(ModelType, Payload) => await adminCall({ModelType, Payload, Method:"Create"});

const readCall = async(ModelType, key) => await adminCall({ModelType, key, Method: "Read"});
const listByParentCall = async(ModelType, ParentId) => await adminCall({ModelType, Payload: ParentId, Method: "ReadListByParent"});
const listAllCall = async(ModelType) => await adminCall({ModelType, Method: "ReadAll"});
const extractNamespace = (modelType) => modelType.substr(modelType.lastIndexOf('.') + 1);

export const Api = {
    apiCall,
    adminCall,
    
    marketTextCall,
    marketCall,
    createCall,
    listByParentCall,
    listAllCall,
    readCall
}