import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from './styles/theme';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './lib/queryClient';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import '@fontsource/inter';
import { cognitoUserPoolConfig } from './env';

Amplify.configure({ 
  Auth: {
    region: cognitoUserPoolConfig().REGION,
    userPoolId: cognitoUserPoolConfig().USER_POOL_ID,
    userPoolWebClientId: cognitoUserPoolConfig().USER_POOL_APP_CLIENT_ID,
    
  },
  API: {},
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>,
);
