import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Box, LinearProgress, Stack } from '@mui/material';
import { withAuthenticator } from '@aws-amplify/ui-react';
import ebadiLogo from './assets/images/ebadiai-high-resolution-logo-transparent.png';
import { RootLayout } from './components/layout';
import { appConfig } from './env';

const ChildTabs = lazy(() => import('./components/ChildTabs'));
const Chat = lazy(() => import('./pages/ServiceManagement/Chat'));

function App() {
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    document.title = "Playground";
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <Stack sx={{ justifyContent: 'center', alignItems: 'center', height: '100vh', bgcolor: '#f5f5f5' }}>
        <Box component="img" src={ebadiLogo} alt="Sx Logo" sx={{ maxWidth: '80%', margin: '2rem auto', zIndex: 1 }} />
        <LinearProgress color="secondary" sx={{ width: '50%', zIndex: 2 }} />
      </Stack>
    );
  }

  return (
    <RootLayout>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<ChildTabs />} />
          <Route path="chat" element={<Chat />} />
          <Route path="*" element={<ChildTabs />} />
        </Routes>
      </Suspense>
    </RootLayout>
  );
}

export default withAuthenticator(App, {
  socialProviders: ['google'],
});
