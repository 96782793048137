import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { AppBar, Toolbar, Button, IconButton, MenuItem, Menu, Stack, Box, Typography } from '@mui/material';
import { ModalWrapper } from './ui';
import { useModal } from '../hooks/useModal';
import ebadiLogo from '../assets/images/ebadiai-high-resolution-logo-transparent.png';
import versionIllustration from '../assets/images/ebadiai-high-resolution-logo-transparent.png';
import packageJson from '../../package.json';
import { useLocation, useNavigate } from 'react-router-dom';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import {Api} from '../lib/api';

const AboutModal = ({ isOpen, handleClose }) => {
  return (
    <ModalWrapper isOpen={isOpen} handleClose={handleClose} boxContainerProps={{ width: 500, height: 600 }}>
      <Stack sx={{ width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', gap: 3 }}>
      <Box>
          <Typography variant="h4" gutterBottom>
          </Typography>
        </Box>
        <img loading="lazy" src={versionIllustration} alt="Version" height="200" />
        <Box>
          <Typography variant="h6" gutterBottom>
            ENTERPRISE CONSOLE
          </Typography>
          <Typography gutterBottom align="center">
            Version {packageJson.version}
          </Typography>
        </Box>
      </Stack>
    </ModalWrapper>
  );
};

export const Navbar = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [avatar, setAvatar] = React.useState(null);
  const [userId, setUserId] = React.useState(null);

  const open = Boolean(anchorEl);
  const openAvatar = Boolean(avatar);
  const { open: openAboutModal, close: closeAboutModal, isOpen: isAboutModalOpen } = useModal();
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  
  const handleClose = () => {
    setAnchorEl(null);
    setAvatar(null);
  }

  const initUserId = async () => {
      var response = await Api.adminCall({Method: 'Me', ModelType: 'EAI.Service.IAM.User'});
      setUserId(response.Name);
    }

    if(!userId) initUserId();
  
  const handleAvatarClick = (event) => setAvatar(event.currentTarget);


  return (
    <>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 10 }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>

          <IconButton color="inherit" onClick={handleClick} edge="start" sx={{ mr: 2 }}>
            <Box component={MenuOutlinedIcon} />
          </IconButton>

          <IconButton color="inherit" onClick={handleAvatarClick} edge="end" >
            <Box component={PersonOutlineOutlinedIcon} />
          </IconButton>

        </Toolbar>
      </AppBar>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} PaperProps={{ sx: { minWidth: 150 } }}>

        <MenuItem sx={{ color: location.pathname === '/' ? 'primary.main' : 'grey.600' }} onClick={() => { handleClose(); navigate('/'); }} >
          <Box component={HomeOutlinedIcon} sx={{ mr: 1, fontSize: '1.3rem' }} /> Home
        </MenuItem>

        <MenuItem sx={{ color: 'grey.600' }} onClick={() => { handleClose(); openAboutModal(); }} >
          <Box component={InfoOutlinedIcon} sx={{ mr: 1, fontSize: '1.3rem' }} /> About
        </MenuItem>

      </Menu>

      <Menu anchorEl={avatar} open={openAvatar} onClose={handleClose} PaperProps={{ sx: { minWidth: 150 } }}>

        <MenuItem sx={{ color: location.pathname === '/' ? 'primary.main' : 'grey.600' }} onClick={() => { handleClose(); navigate('/'); }} >
          <Box sx={{ mr: 1, fontSize: '1.3rem' }} /> {userId}
        </MenuItem>

        <MenuItem sx={{ color: 'grey.600' }} onClick={() => { handleClose(); Auth.signOut(); }} >
          <Box component={LogoutOutlinedIcon} sx={{ mr: 1, fontSize: '1.3rem' }} /> Sign Out
        </MenuItem>

      </Menu>

      {isAboutModalOpen && <AboutModal isOpen={isAboutModalOpen} handleClose={closeAboutModal} />}
    </>
  );
};
