import React from 'react';
import Button from '@mui/material/Button';

export const CustomButton = ({ children, ...props }) => {
  return (
    <Button {...props} variant="contained" sx={{ alignSelf: 'center', minWidth: 150, ...props.sx }}>
      {children}
    </Button>
  );
};
