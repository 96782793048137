import { create } from 'zustand';

export const useDomainStore = create((set) => ({
  isDomainTreeOpen: true,
  setIsDomainTreeOpen: (isDomainTreeOpen) =>
    set((state) => ({
      ...state,
      isDomainTreeOpen,
    })),

  expanded: [],
  setExpanded: (expanded) =>
    set((state) => ({
      ...state,
      expanded,
    })),
  selected: [],
  
  setSelected: (selected) =>
    set((state) => ({
      ...state,
      selected,
    })),
  activeDomain: null,
  setActiveDomain: (activeDomain) =>
    set((state) => ({
      ...state,
      activeDomain,
    })),
}));
