import * as MuiIcons from '@mui/icons-material';
import { toast } from 'react-toastify';

export const iconLookup = (iconName) => {
  if (MuiIcons[iconName]) {
    return MuiIcons[iconName];
  } else {
    // console.warn(`Icon '${iconName}' not found.`);
    toast.error('Invalid Icon');
    return null;
  }
};
