import React, { useState } from 'react';
import { useModal } from '../hooks/useModal';
import {Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Skeleton, Stack, Typography} from '@mui/material';
import ModelDataPanel from './ModelDataPanel';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import { useGlobalStore } from '../store/global.store';
import { useChildDomains, useDomains, useDomainStore } from '../features/domains';
import { iconLookup } from '../utils/iconLookup';
import { useNavigate } from 'react-router-dom';

const DomainTreeItem = ({ domain, handleMenuOpen, handleIsDomainChildTypes }) => {

  const { data: subDomains } = useChildDomains(domain.FullPath);
  const hasSubDomains = subDomains && subDomains.length > 0;
  const { setActiveDomain } = useDomainStore();

  return (
    <TreeItem
      nodeId={domain.FullPath}
      onClick={() => {
        setActiveDomain(domain);
      }}
      onContextMenu={(e) => {
        setActiveDomain(domain);
        handleIsDomainChildTypes(domain?.ChildTypes);
        handleMenuOpen(e, domain.FullPath);
      }}
      sx={{
        color: 'grey.700',
        '.MuiTreeItem-content.Mui-selected': { bgcolor: 'primary.main', color: 'common.white' },
        '.MuiTreeItem-content.Mui-selected:hover': { bgcolor: 'primary.main', color: 'common.white' },
        '.MuiTreeItem-content.Mui-selected.Mui-focused': {
          bgcolor: 'primary.main',
          color: 'common.white',
        },
      }}
      label={
        <Box sx={{ py: 0.5, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {domain.Icon ? <Box component={iconLookup(domain.Icon)} sx={{ fontSize: '0.8rem' }} /> : null}
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
              {domain.Name}
            </Typography>
          </Box>

          <IconButton
            size="small"
            onClick={(e) => {
              setActiveDomain(domain);
              handleIsDomainChildTypes(domain?.ChildTypes);
              handleMenuOpen(e, domain.FullPath);
            }}
            sx={{ color: 'inherit' }}
          >
            <MoreHorizOutlinedIcon />
          </IconButton>
        </Box>
      }
    >
      {hasSubDomains ? (<DomainTree parentDomains={subDomains} handleMenuOpen={handleMenuOpen} handleIsDomainChildTypes={handleIsDomainChildTypes} />) : null}
    </TreeItem>
  );
};

const DomainTree = ({ parentDomains, handleMenuOpen, handleIsDomainChildTypes }) => {
  return parentDomains.map((domain) => (
    <DomainTreeItem key={domain.FullPath} domain={domain} handleMenuOpen={handleMenuOpen} handleIsDomainChildTypes={handleIsDomainChildTypes} />
  ));
};

export default function EnterpriseTreeView() {
  const navigate = useNavigate();

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);
  const [isChildTypes, setIsChildTypes] = useState(false);

  const { data: parentDomains, isLoading: isParentDomainsLoading } = useDomains();
  const { drawerWidth, isDrawerOpen, setIsDrawerOpen, isLocked, setIsLocked } = useGlobalStore();
  const { expanded, selected, setExpanded, setSelected } = useDomainStore();
  const { isOpen: isModalOpen, close: closeModal, open: openModal } = useModal();
  const { isOpen: isSubOpen, close: closeSub, open: openSub } = useModal();

  const handleToggle = (_e, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (_e, nodeIds) => {
    navigate(`/${[nodeIds]}`);
    setSelected(nodeIds);
  };

  const handleMenuOpen = (e, nodeId) => {
    e.preventDefault();
    e.stopPropagation();
    setSelected([nodeId]);
    setMenuAnchorEl(e.currentTarget);
    setContextMenu(contextMenu === null ? { mouseX: e.clientX + 2, mouseY: e.clientY - 6 } : null);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setContextMenu(null);
  };

  const handleIsDomainChildTypes = (value) => {
    setIsChildTypes(Boolean(value));
  };

  var hasDomains = parentDomains?.length > 0;
  if(hasDomains && isLocked) setIsLocked(false);

  return (
    <>
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        selected={selected}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
        multiSelect
        sx={{ flexGrow: 1, maxWidth: 400, overflowY: 'auto', userSelect: 'none' }}
      >
        {isParentDomainsLoading ? (
          <Stack sx={{ overflowY: 'auto', my: 2, gap: 0.5 }}>
            {new Array(10).fill(0).map((_item, idx) => (
              <Skeleton key={idx} variant="rectangular" height={36} />
            ))}
          </Stack>
        ) : parentDomains ? (
          <DomainTree parentDomains={parentDomains} handleMenuOpen={handleMenuOpen} handleIsDomainChildTypes={handleIsDomainChildTypes} />
        ) : (
          <p>No parent domains found</p>
        )}
      </TreeView>

      <Menu
        open={Boolean(menuAnchorEl) || contextMenu !== null}
        anchorEl={menuAnchorEl || null}
        onClose={handleMenuClose}
        anchorReference={contextMenu !== null ? 'anchorPosition' : 'anchorEl'}
        anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
        slotProps={{ paper: { sx: { minWidth: 200 } } }}
      >
        
        <MenuItem onClick={() => {
          openSub();
          handleMenuClose();
        }}>
          <ListItemIcon sx={{ fontSize: '1.5rem' }}> <AccountTreeOutlinedIcon /> </ListItemIcon>
          <ListItemText>Add Sub Domain</ListItemText>
        </MenuItem>

        <MenuItem onClick={() => {
          openModal();
          handleMenuClose();
        }}>
          <ListItemIcon sx={{ fontSize: '1.5rem' }}> <DriveFileRenameOutlineOutlinedIcon /> </ListItemIcon>
          <ListItemText>Update</ListItemText>
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }} onClick={() => handleMenuClose()}>
          <ListItemIcon sx={{ fontSize: '1.5rem' }}> <DeleteOutlinedIcon /> </ListItemIcon>
          <ListItemText sx={{ color: 'error.main' }}>Delete</ListItemText>
        </MenuItem>

      </Menu>

      {isModalOpen && (<ModelDataPanel fullPath={selected[0]} handleClose={closeModal} isOpen={isModalOpen} modelType='EAI.Service.Domain' />)} 
      {isSubOpen && (<ModelDataPanel parentPath={selected[0]} handleClose={closeSub} isOpen={isSubOpen} modelType='EAI.Service.Domain' />)} 
    </>
  );
}
