import React from 'react';
import { Box, Drawer } from '@mui/material';
import { useResizeableContainer } from '../../hooks/useResizeableContainer';

export const Sidebar = ({ children, drawerWidth, open }) => {
  const { enableResize, width } = useResizeableContainer(drawerWidth);

  return (
    <Drawer
      open={open}
      sx={{ width, flexShrink: 0, '& .MuiDrawer-paper': { width, boxSizing: 'border-box' } }}
      variant="persistent"
      anchor="left"
      PaperProps={{ sx: { overflowX: 'hidden' } }}
    >
      <Box
        sx={{ position: 'absolute', width: '4px', top: 0, right: -1, bottom: 0, cursor: 'col-resize' }}
        onMouseDown={enableResize}
      />

      {children}
    </Drawer>
  );
};
