import { create } from 'zustand';

export const useGlobalStore = create((set) => ({
  drawerWidth: 240,
  isDrawerOpen: true,
  setIsDrawerOpen: (isDrawerOpen) =>
    set((state) => ({
      ...state,
      isDrawerOpen: isDrawerOpen && !state.isLocked
    })),
  
  isLocked: true,
  setIsLocked: (isLocked) =>
    set((state) => ({
      ...state,
      isLocked,
    }))
}));
